<template>
  <div>
    <b-card :title="$t('Personal Information')">
      <b-row>
        <b-col
          cols="12"
          lg="4"
        >
          <b-form-group>
            <label for="basic-password">{{ $t('Birth date') }}</label>
            <div class="ctrl-pr-pickr">
              <flat-pickr
                v-model="dateft"
                class="form-control"
                :config="{
                  minDate: 'today',
                  dateFormat: 'd-m-Y',
                }"
                :placeholder="$t('Birth date')"
              />
              <feather-icon
                size="24"
                icon="CalendarIcon"
                class="ctrl-icon"
              />
            </div>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          lg="4"
        >
          <b-form-group>
            <label for="basic-password">{{ $t('Phone') }}</label>
            <b-input-group>
              <b-form-input
                :placeholder="$t('Phone')"
                value="09899998999"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          lg="4"
        >
          <b-form-group>
            <label for="basic-password">{{ $t('Email') }}</label>
            <b-input-group>
              <b-form-input
                :placeholder="$t('Email')"
                value="hoanglong9999@gmail.com"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          lg="4"
        >
          <b-form-group
            :label="`${ $t('Gender') }`"
            label-for="gender"
            label-class="mb-1"
          >
            <div class="d-flex">
              <b-form-radio
                v-model="selected"
                selected
                name="some-radios"
                value="A"
              >
                {{ $t('Male') }}
              </b-form-radio>
              <b-form-radio
                v-model="selected"
                name="some-radios"
                value="B"
                class="ml-2"
              >
                {{ $t('Female') }}
              </b-form-radio>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <b-card :title="$t('Identity card')">
      <b-row>
        <b-col
          cols="12"
          lg="6"
        >
          <app-timeline>
            <app-timeline-item>
              <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1">
                <h6>{{ $t('Front side') }}</h6>
              </div>
              <img
                src="https://media.vov.vn/sites/default/files/styles/large/2022-03/dung-chung-minh-nhan-dan-gia-bi-xu-phat-nhu-the-nao.jpg"
                alt="avc"
              >
            </app-timeline-item>
            <app-timeline-item>
              <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1">
                <h6>{{ $t('Back side') }}</h6>
              </div>
              <img
                src="https://media.vov.vn/sites/default/files/styles/large/2022-03/dung-chung-minh-nhan-dan-gia-bi-xu-phat-nhu-the-nao.jpg"
                alt="avc"
              >
            </app-timeline-item>
          </app-timeline>
        </b-col>
        <b-col
          cols="12"
          lg="6"
        >
          <b-form-group>
            <label for="basic-password">{{ $t('ID number') }}</label>
            <b-input-group>
              <b-form-input
                :placeholder="$t('ID number')"
                value="36299998999"
              />
            </b-input-group>
          </b-form-group>
          <b-form-group>
            <label for="basic-password">{{ $t('Date of issue') }}</label>
            <div class="ctrl-pr-pickr">
              <flat-pickr
                v-model="dateft"
                class="form-control"
                :config="{
                  minDate: 'today',
                  dateFormat: 'd-m-Y',
                }"
                :placeholder="$t('Date of issue')"
              />
              <feather-icon
                size="24"
                icon="CalendarIcon"
                class="ctrl-icon"
              />
            </div>
          </b-form-group>
          <b-form-group>
            <label for="basic-password">{{ $t('Place of issue') }}</label>
            <b-input-group>
              <b-form-input
                :placeholder="$t('Place of issue')"
                value="Cần Thơ"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <b-card :title="$t('Information of banks account')">
      <b-row>
        <b-col
          cols="12"
          lg="4"
        >
          <b-form-group>
            <label for="basic-password">{{ $t('Bank name') }}</label>
            <b-input-group>
              <b-form-input
                :placeholder="$t('Bank name')"
                value="Techcombank"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          lg="4"
        >
          <b-form-group>
            <label for="basic-password">{{ $t('Beneficiary Name') }}</label>
            <b-input-group>
              <b-form-input
                :placeholder="$t('Beneficiary Name')"
                value="Hoàng Long"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          lg="4"
        >
          <b-form-group>
            <label for="basic-password">{{ $t('Beneficiary Account Number') }}</label>
            <b-input-group>
              <b-form-input
                :placeholder="$t('Beneficiary Account Number')"
                value="09899998999"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          lg="4"
        >
          <b-form-group>
            <label for="basic-password">{{ $t('Branch') }}</label>
            <b-input-group>
              <b-form-input
                :placeholder="$t('Branch')"
                value="Bầu Cát"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  // BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BInputGroup,
  BFormInput,
  BFormRadio,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import flatPickr from 'vue-flatpickr-component'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
  components: {
    BCard,
    AppTimeline,
    AppTimelineItem,
    // BAvatar,
    BRow,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BFormRadio,
    BCol,
    flatPickr,
  },
  data() {
    return {
      selected: 'A',
      dateft: '7-21-2022',
      datenc: '7-21-2002',
    }
  },
}
</script>

<style lang="sass" scoped>
.ctrl-pr-pickr
  position: relative

  .ctrl-icon
    position: absolute
    top: 7px
    right: 7px
    color: #d8d6de
    pointer-events: none

img
  max-width: 150px
  width: 100%
  height: 100%
</style>
