<template>
  <div>

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="userData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching user data
      </h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-users-list'}"
        >
          User List
        </b-link>
        for other users.
      </div>
    </b-alert>

    <b-tabs
      pills
    >
      <!-- Tab: Account -->
      <b-tab active>
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{ $t('Account') }}</span>
        </template>
        <template v-if="userData">
          <!-- First Row -->
          <b-row>
            <b-col
              cols="12"
              xl="9"
              lg="8"
              md="7"
            >
              <UserViewUserInfoCard :user-data="userData" />
            </b-col>
            <b-col
              cols="12"
              md="5"
              xl="3"
              lg="4"
            >
              <UserViewUserPlanCard />
            </b-col>
          </b-row>
          <AssetFluctuationsList />
          <invoice-list />
        </template>
      </b-tab>
      <!-- Tab: Information -->
      <b-tab>
        <template #title>
          <feather-icon
            icon="InfoIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{ $t('Personal Information') }}</span>
        </template>
        <template v-if="userData">
          <!-- First Row -->
          <UserViewUserTimelineCard />
        </template>
      </b-tab>
      <!-- Tab: Social -->
      <b-tab>
        <template #title>
          <feather-icon
            icon="Share2Icon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{ $t('Payment history') }}</span>
        </template>
        <UsersHistoryPaying />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import { ref, onUnmounted } from '@vue/composition-api'
import {
  BRow, BCol, BAlert, BLink, BTab, BTabs,
} from 'bootstrap-vue'
import InvoiceList from './InvoiceList.vue'
import AssetFluctuationsList from './AssetFluctuationsList.vue'
import userStoreModule from '../userStoreModule'
import UserViewUserInfoCard from './UserViewUserInfoCard.vue'
import UserViewUserPlanCard from './UserViewUserPlanCard.vue'
import UserViewUserTimelineCard from './UserViewUserTimelineCard.vue'
import UsersHistoryPaying from './UsersHistoryPaying.vue'
// import UserViewUserPermissionsCard from './UserViewUserPermissionsCard.vue'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    BTab,
    BTabs,
    // Local Components
    UserViewUserInfoCard,
    UserViewUserPlanCard,
    UserViewUserTimelineCard,
    AssetFluctuationsList,
    UsersHistoryPaying,
    // UserViewUserPermissionsCard,

    InvoiceList,
  },
  setup() {
    const userData = ref(null)

    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    store.dispatch('app-user/fetchUser', { id: router.currentRoute.params.id })
      .then(response => { userData.value = response.data })
      .catch(error => {
        if (error.response.status === 404) {
          userData.value = undefined
        }
      })

    return {
      userData: [
        {
          id: 1,
          fullName: 'Galen Slixby',
          company: 'Yotz PVT LTD',
          role: 'editor',
          username: 'gslixby0',
          country: 'El Salvador',
          contact: '(479) 232-9151',
          email: 'gslixby0@abc.net.au',
          currentPlan: 'enterprise',
          status: 'inactive',
          avatar: 'https://pixinvent.com/demo/vuexy-vuejs-admin-dashboard-template/demo-1/img/9.3fd39137.png',
        },
        {
          id: 2,
          fullName: 'Halsey Redmore',
          company: 'Skinder PVT LTD',
          role: 'author',
          username: 'hredmore1',
          country: 'Albania',
          contact: '(472) 607-9137',
          email: 'hredmore1@imgur.com',
          currentPlan: 'team',
          status: 'pending',
          avatar: 'https://pixinvent.com/demo/vuexy-vuejs-admin-dashboard-template/demo-1/img/9.3fd39137.png',
        },
        {
          id: 3,
          fullName: 'Marjory Sicely',
          company: 'Oozz PVT LTD',
          role: 'maintainer',
          username: 'msicely2',
          country: 'Russia',
          contact: '(321) 264-4599',
          email: 'msicely2@who.int',
          currentPlan: 'enterprise',
          status: 'active',
          avatar: 'https://pixinvent.com/demo/vuexy-vuejs-admin-dashboard-template/demo-1/img/9.3fd39137.png',
        },
        {
          id: 4,
          fullName: 'Cyrill Risby',
          company: 'Oozz PVT LTD',
          role: 'maintainer',
          username: 'crisby3',
          country: 'China',
          contact: '(923) 690-6806',
          email: 'crisby3@wordpress.com',
          currentPlan: 'team',
          status: 'inactive',
          avatar: 'https://pixinvent.com/demo/vuexy-vuejs-admin-dashboard-template/demo-1/img/9.3fd39137.png',
        },
        {
          id: 5,
          fullName: 'Maggy Hurran',
          company: 'Aimbo PVT LTD',
          role: 'subscriber',
          username: 'mhurran4',
          country: 'Pakistan',
          contact: '(669) 914-1078',
          email: 'mhurran4@yahoo.co.jp',
          currentPlan: 'enterprise',
          status: 'pending',
          avatar: 'https://pixinvent.com/demo/vuexy-vuejs-admin-dashboard-template/demo-1/img/9.3fd39137.png',
        },
      ],
    }
  },
}
</script>

<style>

</style>
