<template>

  <!-- Table Container Card -->
  <b-card class="mt-2 customer-card">
    <div
      class="title-block"
      style="padding: 1.5rem;"
    >
      <div
        class="title-mod"
        style="font-weight: 500; font-size: 1.285rem;"
      >
        Danh sách đơn hàng
      </div>
      <div class="mt-2 mb-1">

        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Entries</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
            <!-- <b-button
              variant="primary"
              :to="{ name: 'apps-invoice-add'}"
            >
              Add Record
            </b-button> -->
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Tìm kiếm mã đơn hàng..."
              />
              <v-select
                v-model="statusFilter"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="statusOptions"
                class="invoice-filter-select"
                placeholder="Trạng thái"
              >
                <template #selected-option="{ label }">
                  <span class="text-truncate overflow-hidden">
                    {{ label }}
                  </span>
                </template>
              </v-select>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>

    <b-table
      ref="refInvoiceListTable"
      :items="fetchInvoices"
      responsive
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      empty-text="No matching records found"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
    >

      <template #head(invoiceStatus)>
        <feather-icon
          icon="TrendingUpIcon"
          class="mx-auto"
        />
      </template>

      <!-- Column: Id -->
      <template #cell(id)="data">
        <b-link
          :to="{ name: 'apps-invoice-preview', params: { id: data.item.id }}"
          class="font-weight-bold"
        >
          #{{ data.value }}
        </b-link>
      </template>

      <!-- Column: Invoice Status -->
      <template #cell(invoiceStatus)="data">
        <b-avatar
          :id="`invoice-row-${data.item.id}`"
          size="32"
          :variant="`light-${resolveInvoiceStatusVariantAndIcon(data.item.invoiceStatus).variant}`"
        >
          <feather-icon
            :icon="resolveInvoiceStatusVariantAndIcon(data.item.invoiceStatus).icon"
          />
        </b-avatar>
        <b-tooltip
          :target="`invoice-row-${data.item.id}`"
          placement="top"
        >
          <p class="mb-0">
            {{ data.item.invoiceStatus }}
          </p>
          <p class="mb-0">
            Balance: {{ data.item.balance }}
          </p>
          <p class="mb-0">
            Due Date: {{ data.item.dueDate }}
          </p>
        </b-tooltip>
      </template>

      <!-- Column: Client -->
      <template #cell(client)="data">
        <b-media vertical-align="center">
          <template #aside>
            <b-avatar
              size="32"
              :src="data.item.avatar"
              :text="avatarText(data.item.client.name)"
              :variant="`light-${resolveClientAvatarVariant(data.item.invoiceStatus)}`"
            />
          </template>
          <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.client.name }}
          </span>
          <small class="text-muted">{{ data.item.client.companyEmail }}</small>
        </b-media>
      </template>

      <!-- Column: Issued Date -->
      <template #cell(issuedDate)="data">
        <span class="text-nowrap">
          {{ data.value }}
        </span>
      </template>

      <!-- Column: Balance -->
      <template #cell(balance)="data">
        <template v-if="data.value === 0">
          <b-badge
            pill
            variant="light-success"
          >
            Paid
          </b-badge>
        </template>
        <template v-else>
          {{ data.value }}
        </template>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">

        <div class="text-nowrap">
          <!-- Dropdown -->
          <b-dropdown
            variant="link"
            toggle-class="p-0"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item>
              <feather-icon icon="DownloadIcon" />
              <span class="align-middle ml-50">Download</span>
            </b-dropdown-item>
            <b-dropdown-item :to="{ name: 'apps-invoice-edit', params: { id: data.item.id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>
            <b-dropdown-item>
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
            <b-dropdown-item>
              <feather-icon icon="CopyIcon" />
              <span class="align-middle ml-50">Duplicate</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>

    </b-table>
    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalInvoices"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
  </b-card>

</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import useInvoicesList from './useInvoiceList'

import invoiceStoreModule from './invoiceStoreModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    // BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,

    vSelect,
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-invoice'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const statusOptions = [
      'Đã tải',
      'Bản nháp',
      'Đã trả',
      'Thanh toán một phần',
      'Quá hạn',
    ]

    const {
      fetchInvoices,
      tableColumns,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,

      statusFilter,

      refetchData,

      resolveInvoiceStatusVariantAndIcon,
      resolveClientAvatarVariant,
    } = useInvoicesList()

    return {
      tableColumns,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,

      statusFilter,

      refetchData,

      statusOptions,

      avatarText,
      resolveInvoiceStatusVariantAndIcon,
      resolveClientAvatarVariant,
      fetchInvoices,
      fetchInvoices1: [
        {
          id: 4987,
          issuedDate: '13 Dec 2019',
          client: {
            address: '7777 Mendez Plains',
            company: 'Hall-Robbins PLC',
            companyEmail: 'don85@johnson.com',
            country: 'USA',
            contact: '(616) 865-4180',
            name: 'Jordan Stevenson',
          },
          service: 'Software Development',
          total: 3428,
          avatar: '',
          invoiceStatus: 'Paid',
          balance: '$724',
          dueDate: '23 Apr 2019',
        },
        {
          id: 4988,
          issuedDate: '17 Jul 2019',
          client: {
            address: '04033 Wesley Wall Apt. 961',
            company: 'Mccann LLC and Sons',
            companyEmail: 'brenda49@taylor.info',
            country: 'Haiti',
            contact: '(226) 204-8287',
            name: 'Stephanie Burns',
          },
          service: 'UI/UX Design & Development',
          total: 5219,
          avatar: 'https://pixinvent.com/demo/vuexy-vuejs-admin-dashboard-template/demo-1/img/9-small.30df7a62.png',
          invoiceStatus: 'Downloaded',
          balance: 0,
          dueDate: '15 Dec 2019',
        },
        {
          id: 4989,
          issuedDate: '19 Oct 2019',
          client: {
            address: '5345 Robert Squares',
            company: 'Leonard-Garcia and Sons',
            companyEmail: 'smithtiffany@powers.com',
            country: 'Denmark',
            contact: '(955) 676-1076',
            name: 'Tony Herrera',
          },
          service: 'Unlimited Extended License',
          total: 3719,
          avatar: '',
          invoiceStatus: 'Paid',
          balance: 0,
          dueDate: '03 Nov 2019',
        },
        {
          id: 4990,
          issuedDate: '06 Mar 2020',
          client: {
            address: '19022 Clark Parks Suite 149',
            company: 'Smith, Miller and Henry LLC',
            companyEmail: 'mejiageorge@lee-perez.com',
            country: 'Cambodia',
            contact: '(832) 323-6914',
            name: 'Kevin Patton',
          },
          service: 'Software Development',
          total: 4749,
          avatar: 'https://pixinvent.com/demo/vuexy-vuejs-admin-dashboard-template/demo-1/img/9-small.30df7a62.png',
          invoiceStatus: 'Sent',
          balance: 0,
          dueDate: '11 Feb 2020',
        },
        {
          id: 4991,
          issuedDate: '08 Feb 2020',
          client: {
            address: '8534 Saunders Hill Apt. 583',
            company: 'Garcia-Cameron and Sons',
            companyEmail: 'brandon07@pierce.com',
            country: 'Martinique',
            contact: '(970) 982-3353',
            name: 'Mrs. Julie Donovan MD',
          },
          service: 'UI/UX Design & Development',
          total: 4056,
          avatar: '',
          invoiceStatus: 'Draft',
          balance: '$815',
          dueDate: '30 Jun 2019',
        },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.customer-card {
  .card-body{
    padding: 0;
  }
}
</style>
